import { useMutation } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';
import { Collapse, Form, Result, Typography } from 'antd';
import { NuButton } from 'components/nuspire';
import { NuspireIcon } from 'components/nuspire/nu-icon';
import { FormikInput } from 'components/shared-components';
import baseTheme from 'components/theme';
import { Formik } from 'formik';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { MAKE_CONNECTION_MUTATION } from '..';
import { ConnectionInstructionsWrapper } from '../index';
import { decodeOAuthState } from '../utils/oauth';
import connector from './cybereason';

const validationSchema = yup.object().shape({
  baseURL: yup.string().url().required(),
  name: yup.string().required(),
  password: yup.string().required(),
  username: yup.string().required(),
});

interface IAuthFormValues {
  baseURL: string;
  name: string;
  password: string;
  username: string;
}

interface ICybereasonAuthFormProps {
  onSubmit: (args: { values: IAuthFormValues; setSubmitting: (isSubmitting: boolean) => void }) => Promise<void>;
}

function CybereasonAuthForm({ onSubmit }: ICybereasonAuthFormProps) {
  return (
    <Formik
      initialValues={{
        baseURL: ``,
        name: ``,
        password: ``,
        username: ``,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit({ values, setSubmitting });
      }}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, errors, dirty }) => (
        <Form
          layout="vertical"
          onFinish={() => {
            submitForm();
          }}
        >
          <FormikInput
            name="name"
            label="Connection Name"
            required
            tooltip="This will be used in myNuspire when referencing this connection."
          />
          <FormikInput
            name="baseURL"
            label="Instance URL"
            required
            tooltip="Ex: https://my-instance.cybereason.net"
            placeholder="Ex: https://my-instance.cybereason.net"
          />
          <FormikInput
            name="username"
            label="Username"
            required
            tooltip="The username of the user that you wish to authenticate with."
            placeholder="my.name@domain.com"
          />
          <FormikInput
            name="password"
            label="Password"
            required
            tooltip="The password of the username you wish to authenticate with."
            passwordInput
          />
          <Form.Item>
            <NuButton
              type="primary"
              htmlType="submit"
              disabled={!dirty || Object.keys(errors).length > 0 || isSubmitting}
              loading={isSubmitting}
            >
              Submit
            </NuButton>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}

function CybereasonConnectionInstructions() {
  return (
    <Collapse style={{ marginBottom: '32px' }} defaultActiveKey="instructions">
      <Collapse.Panel key="instructions" header="Connection Instructions">
        <ConnectionInstructionsWrapper>
          <Typography.Paragraph>
            myNuspire leverages Cybereason REST APIs which are authenticated with username and password. Follow the
            instructions below in order to set up a Cybereason Connection.
          </Typography.Paragraph>

          <Typography.Title level={4}>Ensure the User Has Proper Permissions</Typography.Title>

          <Typography.Paragraph>
            Certain actions in the Cybereason REST API require specific permissions in order to access them. To
            remediate Malops, the user of the provided Connection must have one of the following permissions assigned to
            it:
            <ul>
              <li>L3 Analyst</li>
              <li>Executive</li>
            </ul>
            In order to poll for Malops, ensure the user of the provided Connection has one of the following permissions
            assigned to it:
            <ul>
              <li>L1 Analyst</li>
              <li>L2 Analyst</li>
              <li>L3 Analyst</li>
              <li>Executive</li>
              <li>API User</li>
            </ul>
            Please make sure that the user has the proper permissions to use the desired actions from the Cybereason
            REST API.
          </Typography.Paragraph>

          <Typography.Title level={4}>Finding the Instance URL</Typography.Title>

          <Typography.Paragraph>
            In your browser&apos;s navbar, you should see your Cybereason instance URL. It should look like this:{' '}
            <code>https://my-instance.cybereason.net</code>. Copy this URL (<strong>only up to the</strong>{' '}
            <code>cybereason.net</code>
            <strong>!</strong>) to use for the Instance URL field below.
          </Typography.Paragraph>
        </ConnectionInstructionsWrapper>
      </Collapse.Panel>
    </Collapse>
  );
}

const Layout = styled.div``;

const Header = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid ${(p) => p.theme.token.colorBorder};
`;

const Content = styled.div`
  padding: 16px 32px;
`;

export default function CybereasonRedirect() {
  const location = useLocation();
  const search = location.search;
  const { authState: oktaAuthState } = useOktaAuth();

  const [makeConnection] = useMutation(MAKE_CONNECTION_MUTATION);
  const [success, setSuccess] = useState<boolean>(false);

  const stateString = new URLSearchParams(search).get('state');
  const authState = decodeOAuthState(stateString);

  if (!oktaAuthState?.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Layout>
      <Header>
        <Typography.Title level={2} style={{ marginBottom: 0 }}>
          <NuspireIcon style={{ color: baseTheme.color.primaryBlue, marginRight: '8px' }} />
          Connect to Cybereason
        </Typography.Title>
      </Header>
      <Content>
        {!success ? (
          <>
            <CybereasonConnectionInstructions />

            <Typography.Title level={3}>Create Connection</Typography.Title>
            <CybereasonAuthForm
              onSubmit={async ({ values, setSubmitting }) => {
                const { clientId } = authState;

                if (!clientId) {
                  throw new Error('No client id was passed');
                }

                const { data, errors } = await makeConnection({
                  variables: {
                    connectorSlug: connector.slug,
                    payloadJson: JSON.stringify(values),
                    clientId,
                  },
                });

                setSubmitting(false);

                const newConnection = data?.makeConnection;

                if (newConnection && !errors) {
                  const newEvent = new CustomEvent(`new-connection-${connector.slug}`, {
                    detail: {
                      connection: newConnection,
                    },
                  });

                  window?.opener?.dispatchEvent(newEvent);

                  setSuccess(true);
                }
              }}
            />
          </>
        ) : (
          <Result status="success" title="Connection was successfully created!" />
        )}
      </Content>
    </Layout>
  );
}
