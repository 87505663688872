import { gql, useMutation } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';
import { Collapse, Form, Result, Typography } from 'antd';
import { NuButton } from 'components/nuspire';
import { NuspireIcon } from 'components/nuspire/nu-icon';
import { FormikInput } from 'components/shared-components';
import baseTheme from 'components/theme';
import { Formik } from 'formik';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { ConnectionInstructionsWrapper } from '../index';
import { decodeOAuthState } from '../utils/oauth';
import connector from './fortisiem';

const MAKE_CONNECTION_MUTATION = gql`
  mutation MakeConnection($connectorSlug: String!, $payloadJson: String!, $clientId: String) {
    makeConnection(connectorSlug: $connectorSlug, payloadJson: $payloadJson, clientId: $clientId) {
      id
      connectorSlug
      name
      createdAt
      updatedAt
    }
  }
`;

const validationSchema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
  baseURL: yup.string().url().required(),
  name: yup.string().required(),
  organization: yup.string().required(),
});

interface IAuthFormValues {
  username: string;
  password: string;
  baseURL: string;
  name: string;
  organization: string;
}

interface IFortisiemAuthFormProps {
  onSubmit: (args: { values: IAuthFormValues; setSubmitting: (isSubmitting: boolean) => void }) => Promise<void>;
}

function FortisiemAuthForm({ onSubmit }: IFortisiemAuthFormProps) {
  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        baseURL: '',
        name: '',
        organization: '',
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit({ values, setSubmitting });
      }}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, errors, dirty }) => (
        <Form
          layout="vertical"
          onFinish={() => {
            submitForm();
          }}
        >
          <FormikInput
            name="name"
            label="API Client Name"
            required
            tooltip="This will be used in myNuspire when referencing this API client."
          />
          <FormikInput name="username" label="Username" required tooltip="Username" />
          <FormikInput name="password" label="Password" required tooltip="Password" type="password" />
          <FormikInput name="organization" label="Organization" required tooltip="Organization" />
          <FormikInput
            name="baseURL"
            label="Instance URL"
            required
            tooltip="Ex: https://0.0.0.0"
            placeholder="Ex: https://0.0.0.0"
          />
          <Form.Item>
            <NuButton
              type="primary"
              htmlType="submit"
              disabled={!dirty || Object.keys(errors).length > 0 || isSubmitting}
              loading={isSubmitting}
            >
              Submit
            </NuButton>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}

function FortisiemConnectionInstructions() {
  return (
    <Collapse style={{ marginBottom: '32px' }} defaultActiveKey="instructions">
      <Collapse.Panel key="instructions" header="API Token Instructions">
        <ConnectionInstructionsWrapper>
          <Typography.Paragraph>
            myNuspire leverages FortiSIEM APIs which are authenticated with a Username, Organization and Password.
            Follow the instructions below in order to create a Connection.
          </Typography.Paragraph>

          <Typography.Title level={4}>Generating your Auth Information</Typography.Title>

          <Typography.Paragraph>
            Your username, organization, and password will be the same information used to login into your FortiSIEM
            instance. Your baseURL will be &quot;https://&quot; combined with your instance IP address (Ex.
            https://0.0.0.0).
          </Typography.Paragraph>
        </ConnectionInstructionsWrapper>
      </Collapse.Panel>
    </Collapse>
  );
}

const Layout = styled.div``;

const Header = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid ${(p) => p.theme.token.colorBorder};
`;

const Content = styled.div`
  padding: 16px 32px;
`;

export default function FortisiemRedirect() {
  const location = useLocation();
  const search = location.search;
  const { authState: oktaAuthState } = useOktaAuth();

  const [makeConnection] = useMutation(MAKE_CONNECTION_MUTATION);
  const [success, setSuccess] = useState<boolean>(false);

  const stateString = new URLSearchParams(search).get('state');
  const authState = decodeOAuthState(stateString);

  if (!oktaAuthState?.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Layout>
      <Header>
        <Typography.Title level={2} style={{ marginBottom: 0 }}>
          <NuspireIcon style={{ color: baseTheme.color.primaryBlue, marginRight: '8px' }} />
          Connect to FortiSIEM
        </Typography.Title>
      </Header>
      <Content>
        {!success ? (
          <>
            <FortisiemConnectionInstructions />

            <Typography.Title level={3}>Create Connection</Typography.Title>
            <FortisiemAuthForm
              onSubmit={async ({ values, setSubmitting }) => {
                const { clientId } = authState;

                if (!clientId) {
                  throw new Error('No client id was passed');
                }

                const { data, errors } = await makeConnection({
                  variables: {
                    connectorSlug: connector.slug,
                    payloadJson: JSON.stringify(values),
                    clientId,
                  },
                });

                setSubmitting(false);

                const newConnection = data?.makeConnection;

                if (newConnection && !errors) {
                  const newEvent = new CustomEvent(`new-connection-${connector.slug}`, {
                    detail: {
                      connection: newConnection,
                    },
                  });

                  window?.opener?.dispatchEvent(newEvent);

                  setSuccess(true);
                }
              }}
            />
          </>
        ) : (
          <Result status="success" title="Connection was successfully created!" />
        )}
      </Content>
    </Layout>
  );
}
