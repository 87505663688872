import { gql } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';
import { WidgetDefinition } from 'components/dashboard/widget-definitions/types';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { ClientConnection } from '../connector-detail/connector-detail';
import { IClientConnection } from '../data/client-connections';
import CARBON_BLACK_CLOUD_CONNECTOR from './carbon-black-cloud';
import CROWDSTRIKE_CONNECTOR_DEFINITION from './crowdstrike';
import CYBEREASON_CONNECTOR_DEFINITION from './cybereason';
import DUO_CONNECTOR_DEFINITION from './duo';
import FORTISIEM_CONNECTOR_DEFINITION from './fortisiem';
import JIRA_CONNECTOR from './jira';
import MERAKI_CONNECTOR_DEFINITION from './meraki';
import PRISMA_CLOUD_CONNECTOR_DEFINITION from './prisma-cloud';
import QUALYS_CONNECTOR_DEFINITION from './qualys';
import SENTINELONE_CONNECTOR_DEFINITION from './sentinelone';
import SLACK_CONNECTOR_DEFINITION from './slack';
import AZURE_CONNECTOR_DEFINITION from './azure/azure';
import { useFeatureFlag } from '../../feature-flags';

export const MAKE_CONNECTION_MUTATION = gql`
  mutation MakeConnection($connectorSlug: String!, $payloadJson: String!, $clientId: String) {
    makeConnection(connectorSlug: $connectorSlug, payloadJson: $payloadJson, clientId: $clientId) {
      id
      connectorSlug
      name
      createdAt
      updatedAt
    }
  }
`;

export const ConnectionInstructionsWrapper = styled.div`
  padding: 1em;
`;

// little overly simplistic for now.
export type ConnectionType = 'cxp_ingest' | 'action' | 'insights';

export interface ConnectionWidgetProps {
  connectionId: string;
}

// TODO: Build function to build full redirect url.
export interface ConnectorRedirect {
  path: string; // will be tacked on the the end of `/redirects/connections/:connectorSlug`
  component: (props: { connectorSlug: string }) => JSX.Element;
}

interface AddConnectionArgs {
  sessionId?: string;
  clientId?: string | null;
}

type InputType =
  | 'checkbox'
  | 'string'
  | 'number'
  | 'boolean'
  | 'method'
  | 'regexp'
  | 'integer'
  | 'float'
  | 'object'
  | 'enum'
  | 'date'
  | 'url'
  | 'hex'
  | 'email';

export type UpdatableField = {
  alertWhenChecked?: ReactNode;
  inputType?: InputType;
  key: string;
  required?: boolean;
  requiredMessage?: string;
  value: string;
};

export interface IConnector {
  slug: string; // uuid.
  name: string;
  description: string;
  connectionTypes: ConnectionType[];
  icon?: React.ReactNode;
  renderId?: (props: { clientConnection: IClientConnection }) => JSX.Element;
  connectionExplanation?: string;
  nuspireOwned?: boolean;

  /**
   * customizer Connector Render.
   */
  connectorRender?: ConnectorRender;

  /**
   * Register Redirects.
   * useful for setting up redirect routes for OAuth2.0 flow
   */
  redirects?: ConnectorRedirect[];

  // trigger add connection flow.
  // handleAddConnection?: (args?: AddConnectionArgs) => void;
  handleAddConnection?: (args: AddConnectionArgs) => void;

  widgets?: WidgetDefinition[];
  updatableAuthFields?: UpdatableField[];
  emptyStateMessage?: JSX.Element | string;
  featureFlag?: string;
}

export interface ConnectorRenderProps {
  connector: IConnector;
}
export type ConnectorRender = (props: ConnectorRenderProps) => JSX.Element;

export interface IConnectionType {
  slug: string;
  name: string;
}

/**
 * These should live in the backend when they become real.
 * debatable whether or not we should use slugs or uuids to identify them.
 *
 * Could easily write a test to ensure all ids are unique.
 */
export const CONNECTORS: IConnector[] = [
  CARBON_BLACK_CLOUD_CONNECTOR,
  CROWDSTRIKE_CONNECTOR_DEFINITION,
  CYBEREASON_CONNECTOR_DEFINITION,
  DUO_CONNECTOR_DEFINITION,
  FORTISIEM_CONNECTOR_DEFINITION,
  JIRA_CONNECTOR,
  MERAKI_CONNECTOR_DEFINITION,
  PRISMA_CLOUD_CONNECTOR_DEFINITION,
  QUALYS_CONNECTOR_DEFINITION,
  SENTINELONE_CONNECTOR_DEFINITION,
  SLACK_CONNECTOR_DEFINITION,
  AZURE_CONNECTOR_DEFINITION,
];

export function useConnector(slug: string) {
  const connector = CONNECTORS.find((c) => c.slug === slug);
  const { oktaAuth } = useOktaAuth();

  if (!connector) {
    return null;
  }

  if (connector.featureFlag) {
    const isEnabled = useFeatureFlag(connector.featureFlag);

    if (!isEnabled) {
      return null;
    }
  }

  return {
    ...connector,
    handleAddConnection: connector.handleAddConnection
      ? async (args?: { clientId?: string | null }) => {
          const clientId = args?.clientId;
          const oktaSession = await oktaAuth.session.get();
          const { id: sessionId } = oktaSession as any;

          if (!connector.handleAddConnection) {
            return;
          }

          connector.handleAddConnection({
            sessionId,
            clientId,
          });
        }
      : undefined,
  };
}

export function findConnectors(args: { connectionTypes: ConnectionType | ConnectionType[] }): {
  connectors: IConnector[];
} {
  const { connectionTypes } = args;
  const searchTypes = Array.isArray(connectionTypes) ? connectionTypes : [connectionTypes];

  return {
    connectors: CONNECTORS.filter((c) => {
      const matchingType = searchTypes.find((t) => c.connectionTypes.includes(t));

      return Boolean(matchingType);
    }),
  };
}

export function getConnectorBySlug(slug: string) {
  return CONNECTORS.find((c) => c.slug === slug) ?? null;
}
