import { useMutation } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';
import { Collapse, Form, Result, Typography } from 'antd';
import { NuButton } from 'components/nuspire';
import { NuspireIcon } from 'components/nuspire/nu-icon';
import { FormikInput } from 'components/shared-components';
import baseTheme from 'components/theme';
import { Formik } from 'formik';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { MAKE_CONNECTION_MUTATION } from '..';
import { ConnectionInstructionsWrapper } from '../index';
import { decodeOAuthState } from '../utils/oauth';
import connector from './duo';

const validationSchema = yup.object().shape({
  secretKey: yup.string().required(),
  integrationKey: yup.string().required(),
  host: yup.string().required(),
  name: yup.string().required(),
});

interface IAuthFormValues {
  secretKey: string;
  integrationKey: string;
  host: string;
  name: string;
}

interface IDuoAuthFormProps {
  onSubmit: (args: { values: IAuthFormValues; setSubmitting: (isSubmitting: boolean) => void }) => Promise<void>;
}

function DuoAuthForm({ onSubmit }: IDuoAuthFormProps) {
  return (
    <Formik
      initialValues={{
        name: '',
        secretKey: '',
        integrationKey: '',
        host: '',
      }}
      onSubmit={async (values: IAuthFormValues, { setSubmitting }) => {
        await onSubmit({ values, setSubmitting });
      }}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, errors, dirty }) => (
        <Form
          layout="vertical"
          onFinish={() => {
            submitForm();
          }}
        >
          <FormikInput
            name="name"
            label="API Client Name"
            required
            tooltip="This will be used in myNuspire when referencing this API client."
          />
          <FormikInput name="secretKey" label="Secret Key" required />
          <FormikInput name="integrationKey" label="Integration Key" required />
          <FormikInput
            name="host"
            label="Hostname"
            required
            tooltip="Ex: api-xxxxxx.duosecurity.com"
            placeholder="Ex: api-xxxxxx.duosecurity.com"
          />
          <Form.Item>
            <NuButton
              type="primary"
              htmlType="submit"
              disabled={!dirty || Object.keys(errors).length > 0 || isSubmitting}
              loading={isSubmitting}
            >
              Submit
            </NuButton>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}

function DuoConnectionInstructions() {
  return (
    <Collapse style={{ marginBottom: '32px' }} defaultActiveKey="instructions">
      <Collapse.Panel key="instructions" header="API Token Instructions">
        <ConnectionInstructionsWrapper>
          <Typography.Paragraph>
            <Typography.Title level={4}>Generating your Auth Information</Typography.Title>
            <Typography.Paragraph>
              To generate a new API Client, login to your Duo dashboard, you must be an account owner to create a new
              Admin API client. Navigate to Applications. Click "Protect an Application" and locate the entry for Admin
              API in the applications list. Click Protect to the far-right to configure the application and get your
              integration key, secret key, and API hostname. Grant access to read auth logs.
            </Typography.Paragraph>
          </Typography.Paragraph>
        </ConnectionInstructionsWrapper>
      </Collapse.Panel>
    </Collapse>
  );
}

const Layout = styled.div``;

const Header = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid ${(p) => p.theme.token.colorBorder};
`;

const Content = styled.div`
  padding: 16px 32px;
`;

export default function DuoRedirect() {
  const location = useLocation();
  const search = location.search;
  const { authState: oktaAuthState } = useOktaAuth();

  const [makeConnection] = useMutation(MAKE_CONNECTION_MUTATION);
  const [success, setSuccess] = useState<boolean>(false);

  const stateString = new URLSearchParams(search).get('state');
  const authState = decodeOAuthState(stateString);

  if (!oktaAuthState?.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Layout>
      <Header>
        <Typography.Title level={2} style={{ marginBottom: 0 }}>
          <NuspireIcon style={{ color: baseTheme.color.primaryBlue, marginRight: '8px' }} />
          Connect to Duo
        </Typography.Title>
      </Header>
      <Content>
        {!success ? (
          <>
            <DuoConnectionInstructions />

            <Typography.Title level={3}>Create Connection</Typography.Title>
            <DuoAuthForm
              onSubmit={async ({ values, setSubmitting }) => {
                const { clientId } = authState;

                if (!clientId) {
                  throw new Error('No client id was passed');
                }

                const { data, errors } = await makeConnection({
                  variables: {
                    connectorSlug: connector.slug,
                    payloadJson: JSON.stringify(values),
                    clientId,
                  },
                });

                setSubmitting(false);

                const newConnection = data?.makeConnection;

                if (newConnection && !errors) {
                  const newEvent = new CustomEvent(`new-connection-${connector.slug}`, {
                    detail: {
                      connection: newConnection,
                    },
                  });

                  window?.opener?.dispatchEvent(newEvent);

                  setSuccess(true);
                }
              }}
            />
          </>
        ) : (
          <Result status="success" title="Connection was successfully created!" />
        )}
      </Content>
    </Layout>
  );
}
