import { useMutation } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';
import { Form as AntForm, Collapse, Result, Typography } from 'antd';
import { NuButton } from 'components/nuspire';
import { NuspireIcon } from 'components/nuspire/nu-icon';
import { FormikInput } from 'components/shared-components';
import baseTheme from 'components/theme';
import { Formik } from 'formik';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import useSearchParams from 'utils/react-hooks/useSearchParams';
import * as yup from 'yup';
import { MAKE_CONNECTION_MUTATION } from '..';
import { ConnectionInstructionsWrapper } from '../index';
import connector from './carbon-black-cloud';

const validationSchema = yup.object().shape({
  orgKey: yup.string().required(),
  apiId: yup.string().required(),
  apiSecretKey: yup.string().required(),
});

type AuthFormFields = {
  hostname: string;
  name: string;
  orgKey: string;
  apiId: string;
  apiSecretKey: string;
};

interface CbcAuthFormProps {
  onSubmit: (args: { values: AuthFormFields; setSubmitting: (isSubmitting: boolean) => void }) => Promise<void>;
}

function CbcAuthForm(props: CbcAuthFormProps) {
  const { onSubmit } = props;

  return (
    <Formik
      initialValues={{
        hostname: '',
        orgKey: '',
        apiId: '',
        apiSecretKey: '',
        name: '',
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit({ values, setSubmitting });
      }}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, errors, dirty }) => (
        <AntForm
          layout="vertical"
          onFinish={() => {
            console.log('submitting form...');
            submitForm();
          }}
        >
          <FormikInput
            name="hostname"
            label="Hostname"
            required
            tooltip={`You can find this by looking at the web address of your Carbon Black Cloud console (ie:
                "https://defense-prod05.conferdeploy.net/").`}
          />
          <FormikInput
            name="name"
            label="API Key Name"
            required
            tooltip="This will be used in myNuspire when referencing this API Key."
          />
          <FormikInput
            name="orgKey"
            label="ORG KEY"
            required
            tooltip="You can find your org_key in the Carbon Black Cloud Console under Settings > API Access."
          />
          <FormikInput
            name="apiId"
            label="API ID"
            required
            tooltip="Copy this from the API Key created in your Carbon Black Console."
          />
          <FormikInput
            name="apiSecretKey"
            label="API SecretKey"
            required
            tooltip="Copy this from the API Key created in your Carbon Black Console."
          />

          <AntForm.Item>
            <NuButton
              type="primary"
              htmlType="submit"
              disabled={!dirty || Object.keys(errors).length > 0 || isSubmitting}
              loading={isSubmitting}
            >
              Submit
            </NuButton>
          </AntForm.Item>
        </AntForm>
      )}
    </Formik>
  );
}

/**
 * CbcConnectionInstructions
 */
function CbcConnectionInstructions() {
  return (
    <Collapse style={{ marginBottom: '32px' }} defaultActiveKey="instructions">
      <Collapse.Panel key="instructions" header="Access Level + API Key Instructions">
        <ConnectionInstructionsWrapper>
          <Typography.Paragraph>
            myNuspire leverages Carbon Black Cloud REST APIs which are authenticated via API Keys. Follow the
            instructions below in order to set up the proper Access Levels and API Key within Carbon Black in order to
            create a Connection.
          </Typography.Paragraph>

          <Typography.Title level={4}>Creating a Custom Access Level</Typography.Title>

          <Typography.Paragraph>
            Since myNuspire utilizes various REST Api’s within Carbon Black, you will need to create a “Custom Access
            Level” in order to allow myNuspire the required privileges. You will be able to update the permissions on
            the Access Level later as you utilize more Actions, Insights, and Widgets.
          </Typography.Paragraph>

          <Typography.Paragraph>
            <ol>
              <li>
                From within your Carbon Black Cloud Console, open the “Add Access Level” panel from Settings &gt; API
                Access &gt; Access Levels Tab.
              </li>
              <li>
                Give the access level a unique name that will remind you that it is used by myNuspire (ie
                “mynuspire-access-levels”). The Description could be something like “Permissions granted to Connection
                within myNuspire platform”.
              </li>
              <li>
                To get started, check the “Read” permission for Alerts &gt; General Information (“org.alerts”). This
                should allow myNuspire to test the Connection after creation as well as power the “Carbon Black Alerts”
                widget. If no permissions are selected, the Connection will not be able to call any of the REST APIs.
                Note, these permissions can always be added to/removed from at later dates.
              </li>
              <li>
                Next, check the “Read” permission for Devices &gt; General Information (“devices”). This will power the
                “Carbon Black Device Summary” widget.
              </li>
              <li>
                Next, check the “Create”, “Read”, “Update”, and “Delete” permissions for Data Forwarder &gt; Settings.
                This will power the “Carbon Black Alert Forwarder” data source. Without these permissions, the
                Connection will not be able to be used to create the data source.
              </li>
            </ol>
          </Typography.Paragraph>

          <Typography.Title level={4}>Creating an API Key</Typography.Title>

          <Typography.Paragraph>
            After the Custom Access Level has been created (see above) you can create the API Key that will be saved in
            the myNuspire Connection.
          </Typography.Paragraph>

          <Typography.Paragraph>
            <ol>
              <li>Navigate to Settings &gt; API Access &gt; API Keys tab in your Carbon Black Console.</li>
              <li>Select “Add API Key” in the far right.</li>
              <li>
                Give the API Key a unique name(ie “mynuspire-api-key”) and select the Access Level created from the
                prior steps.
              </li>
              <li>
                Save your new API Key and you should be prompted with your new API Credentials. Go ahead and copy the
                API Key and Secret into the appropriate fields in the form below.
              </li>
            </ol>
          </Typography.Paragraph>
        </ConnectionInstructionsWrapper>
      </Collapse.Panel>
    </Collapse>
  );
}

const RedirectLayout = styled.div``;
const RedirectHeader = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid ${(p) => p.theme.token.colorBorder};
`;
const RedirectContent = styled.div`
  padding: 16px 32px;
`;

function CbcAuthRedirect() {
  const { parsed: search } = useSearchParams();

  const { authState } = useOktaAuth();

  const [makeConnection] = useMutation(MAKE_CONNECTION_MUTATION);
  const [success, setSuccess] = useState<boolean>(false);

  if (!authState?.isAuthenticated) {
    return <Navigate to="/log-in" replace />;
  }
  // render form

  return (
    <RedirectLayout>
      <RedirectHeader>
        <Typography.Title style={{ marginBottom: 0 }} level={2}>
          <NuspireIcon style={{ color: baseTheme.color.primaryBlue, marginRight: '8px' }} />
          Connect to Carbon Black Cloud API Key
        </Typography.Title>
      </RedirectHeader>
      <RedirectContent>
        {!success ? (
          <>
            <CbcConnectionInstructions />

            <Typography.Title level={3}>Create Connection</Typography.Title>
            <CbcAuthForm
              onSubmit={async ({ values, setSubmitting }) => {
                const { data, errors } = await makeConnection({
                  variables: {
                    connectorSlug: connector.slug,
                    payloadJson: JSON.stringify(values),
                    clientId: search?.clientId,
                  },
                });

                setSubmitting(false);

                const newConnection = data?.makeConnection;

                if (newConnection && !errors) {
                  const newEvent = new CustomEvent(`new-connection-${connector.slug}`, {
                    detail: {
                      connection: newConnection,
                    },
                  });

                  window?.opener?.dispatchEvent(newEvent);

                  setSuccess(true);
                }
              }}
            />
          </>
        ) : (
          <Result status="success" title="Connection was successfully created!" />
        )}
      </RedirectContent>
    </RedirectLayout>
  );
}

export default CbcAuthRedirect;
